/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LogoDisplay from '../components/logoDisplay';
import ContactPageLayout from '../components/contactPageLayout';
import PropTypes from 'prop-types';
import TopMenu from '../components/topMenu';



const FunPerson = ({ color }) => {
    const subtitleContent = (
        
    <div className="text-md">
      <p>
      We’re so glad you’re here! People are in need and we need help serving them! We’re supporting various needs around the world and your volunteerism would go to feed poor families, improve people’s health  but also raise funds for relief efforts around the globe. Would you connect with us so we can change the world together?
      </p>
    </div>
  );
    
  return (
    <Layout to="/connect" label="WHICH IMAGE?" showMenuIcon>
      <SEO title="Vivo Vibe" />
                                      <TopMenu></TopMenu>

                  <section className="h-screen w-screen yellow-bg-img">

      <ContactPageLayout
        color={color}
        title="There are so many who need you!"
        titleClassName="bg-directions"
                           subTitle={subtitleContent}
        subtitleContent={subtitleContent}
      ></ContactPageLayout>
      </section>
      </Layout>
  );
};

FunPerson.propTypes = {
  color: PropTypes.string,
};

FunPerson.defaultProps = {
  color: 'helper',
};
export default FunPerson;
