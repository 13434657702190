import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FaChevronUp } from 'react-icons/fa';
import Img from 'gatsby-image';

const TopMenu = ({
  color,
  children,
  darker,
  image,
  imageLast,
  title,
}) => {
  return (

      <div className="">
   <AniLink paintDrip to="/" hex="#4299e1">
      
                        <div className="flex justify-center">
          <div className="toppers areathing">
            {image && <Img fluid={image.childImageSharp.fluid} />}
        </div>
      </div>
                        </AniLink>
      </div>
  );
};

TopMenu.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  darker: PropTypes.bool,
  image: PropTypes.any,
  imageLast: PropTypes.bool,
  title: PropTypes.string,
};
TopMenu.defaultProps = {
  color: '',
  children: null,
  darker: false,
  image: null,
  imageLast: false,
  title: '',
};

export default TopMenu;
